* {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  background: #151726;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
u,
b,
a {
  color: #fff;
}
header {
  display: flex;
  top: 0px;
  left: -0.390625px !important;
  width: 100%;
  height: 66px;
  background: #f5405f 0% 0% no-repeat padding-box;
  opacity: 1;
  justify-content: space-between;
  align-items: center;
  padding: fixed;
}
.top-headers {
  background: #2d2e38;
  /* position: fixed; */
  width: 100%;
  /* top: 0; */
  z-index: 1000;
  overflow: hidden;
}

.top-headers .fa-chevron-left {
  font-size: 25px;
}

/* margin */
.m-top-10 {
  margin-top: 10px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-top-40 {
  margin-top: 40px;
}
.m-top-50 {
  margin-top: 50px;
}
.m-top-60 {
  margin-top: 60px;
}
.m-top-70 {
  margin-top: 70px;
}
.m-top-80 {
  margin-top: 80px;
}
.m-top-90 {
  margin-top: 90px;
}
.m-top-100 {
  margin-top: 100px;
}

.less-more {
  margin-top: -40px;
}

/* margin - */
.m-top-m-10 {
  margin-top: -10px !important;
}
.m-top-m-20 {
  margin-top: -20px !important;
}
.m-top-m-30 {
  margin-top: -30px;
}
.m-top-m-40 {
  margin-top: -40px;
}
.m-top-m-50 {
  margin-top: -50px;
}
.m-top-m-60 {
  margin-top: -60px;
}
.m-top-m-70 {
  margin-top: -70px;
}
.m-top-m-80 {
  margin-top: -80px;
}
.m-top-m-90 {
  margin-top: -90px;
}
.m-top-m-100 {
  margin-top: -100px;
}

/* my */
.my-top-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}
/* mb */
.pb-bottom-60 {
  margin-bottom: 60px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nunito", sans-serif;
  /* padding-top: 10px; */
  text-align: center;
  letter-spacing: 0.68px;
  color: #ffffff;
  opacity: 1;
}
.top-title {
  font-size: 14px;
}
.dec1 {
  font-size: 12px;
}
.text-8 {
  font-size: 8px !important;
}
.bg-black-3 {
  background: #252a3a !important;
}
.bg-dark {
  background-color: #151726 !important;
  /* min-height: 90vh; */
  /* padding-top: 40px; */
}
.bg-red {
  background-color: #f2405f;
}
.t-color-red {
  color: #ff3751 !important;
}

.btn-audio-record {
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #f2405f;
}
.btn-audio-record i {
  color: #f2405f;
}
.btn-audio-record-1 {
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 2px 5px #bebebe;
}
.btn-audio-record-1 i {
  color: #000;
}

/* .audio-recorder {
  background:#f2405f !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #f2405f;
} */

.welcome-btn {
  border-radius: 23px;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 1;
  /* margin-left: 23px;	 */
}
.welcome-btn-2 {
  border-radius: 23px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  z-index: 2;
  left: -30px;
}
.input {
  border: 2px solid #f2405f;
  background-color: transparent;
  border-radius: 11px 11px 10px 10px;
  /* margin-bottom: 13px; */
  height: 32px;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
}
input:not([type="checkbox"]) {
  font-size: 12px !important;
}
.form-control-cus {
  font-weight: 300;
  font-size: 10px;
  resize: none;
}
.textarea-color {
  height: 100px !important;
  background: #ff3751 !important;
  border: 1px solid #ff3751;
}
.button {
  padding: 10px 40px;
  font-size: 12px;
  border-radius: 35px;
  /* border: 2px solid #f2405f; */
  padding: 7px 20px;
}

/* Operation hours */
.current-day {
  background-color: #ff3751;
  border-radius: 5px;
  color: #fff;
}

/* customModels */

.customModels .modal-dialog {
  margin-top: 20px;
}

.customModels {
  width: 100%;
  border: none !important;
  border-radius: 0 !important;
}
.customModels .modal-content {
  width: 100%;
  padding: 0 !important;
  border: none;
}
.customModels .modal-dialog {
  max-width: fit-content;
  max-height: fit-content;
  margin-top: auto auto;
}
.customModels .fa-solid {
  font-size: 30px;
}
.customModels .imgs {
  max-width: 100% !important;
  max-height: 100%;
}

.btn-control-prev {
  left: 23px;
}
.btn-control-next {
  right: 23px;
}
.btn-control-next,
.btn-control-prev {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 15%; */
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.btn-control-next i,
.btn-control-prev i {
  background: black !important;
  padding: 10px;
  position: absolute;
}

.x-custom {
  position: absolute;
  right: 0;
  background: black !important;
  padding: 10px;
  color: #fff;
  opacity: 0.5;
  /* color: white; */
  /* margin: 5px; */
  font-size: 20px;
  cursor: pointer;
}

/* modal buttons  */
.btn-modal {
  width: 100%;
  max-width: 100px;
  color: white;
  background: #ff3751 !important;
  border: 2px solid #ff3751;
  padding: 7px 20px;
  font-size: 12px;
  border-radius: 14px;
}
.btn-modal-close,
.btn-modal-close:hover,
.btn-modal-close:focus {
  width: 100%;
  max-width: 100px;
  color: white;
  background: #6d6e71 !important;
  border: 2px solid #6d6e71;
  padding: 7px 20px;
  font-size: 12px;
  border-radius: 14px;
}
.btn-modal-close1,
.btn-modal-close:hover,
.btn-modal-close:focus {
  width: 100%;
  /* max-width: 100px; */
  color: white;
  background: #6d6e71 !important;
  border: 2px solid #6d6e71;
  padding: 7px 20px;
  font-size: 12px;
  border-radius: 35px;
}

.btn-cancel,
.btn-cancel:hover,
.btn-cancel:focus {
  color: white;
  background: #6d6e71 !important;
  border: 2px solid #6d6e71 !important;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 14px;
}

.box-b-1 {
  border: 1px solid #ff3751;
  border-radius: 10px;
}

.btn-next-back {
  width: 100%;
  max-width: 280px;
}
.btn-12 {
  /* padding: 0; */
  padding-right: 0px;
  padding-top: 1px;
}

.content-p {
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
}
.primary-color {
  color: #ff3751;
}
.primary-color1 {
  color: #ff3751 !important;
}
.gray-color-1 {
  color: #6d6e71 !important;
}
.yellow-c {
  color: #ffdf58;
}
.t-color-2 {
  color: #414042;
}

.t-color-red {
  color: #ff3751;
}

.text-8 {
  font-size: 8px !important;
}
.text-10 {
  font-size: 10px !important;
}
.text-12 {
  font-size: 12px !important;
}
.text-14 {
  font-size: 14px !important;
}
.text-16 {
  font-size: 16px !important;
}
.text-18 {
  font-size: 18px !important;
}
.text-20 {
  font-size: 20px !important;
}

.primary-border-1 {
  border: 1px solid #ff3751;
}
.primary-border-2 {
  border: 1px solid #ff3751;
}

.border-red-1 {
  border: 1px solid #ff3751;
  border-radius: 8px;
}
.border-red-2 {
  border: 1px solid #ff3751;
  border-radius: 8px;
}
.re-star {
  font-size: 16px;
  color: #ff3751;
  margin-top: 10px !important;
}
.link {
  cursor: pointer;
}
.disable-link {
  pointer-events: none;
  color: #6d6e71 !important ;
  border: 1px solid #6d6e71 !important;
}
.disable-link span {
  pointer-events: none;
  background: #6d6e71 !important ;
  border: 1px solid #6d6e71 !important;
}
.primary-button {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
}
.primary-button-4 {
  background-color: transparent;
  border: 2px solid #ff3751 !important;
  color: #fff;
}

#reader button {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 12px;
}
#reader select {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
  border-radius: 20px;
  /* padding: 10px 15px; */
  font-size: 12px;
}
#reader {
  color: #fff;
  font-size: 12px;
}
#reader #reader__scan_region img {
  width: 100px;
  fill: #ffffff;
}

#reader #html5-qrcode-anchor-scan-type-change {
  display: none !important;
}
#reader #reader__dashboard {
  padding-bottom: 10px;
}

.client-qrcode-img {
  border-radius: 15px;
}
/* #reader img {
display: none;
} */

/* #html5-qrcode-button-camera-stop,
#html5-qrcode-button-camera-permission, #html5-qrcode-button-camera-start {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 12px;
}
#reader img {
  color: #fff;
} */
/* .primary-button:focus, */
.primary-button:hover {
  background-color: #ff3751 !important;
  color: #fff;
  border: 2px solid #ff3751 !important;
}
.primary-button-active {
  background-color: #00a67e;
  border: 2px solid #00a67e;
  color: #fff;
}
.primary-button-active:focus,
.primary-button-active:hover {
  background-color: #00a67e !important;
  color: #fff;
  border: 2px solid #00a67e !important;
}

.secondary-button {
  border: 2px solid #ff3751;
}
.secondary-button,
.secondary-button:focus,
.secondary-button:hover {
  background-color: #ff3751 !important;
  border: 2px solid #ff3751 !important;
  color: #fff;
}
.light-button {
  background-color: #6d6e71 !important;
  border-color: #6d6e71;
  padding: 3px 0px;
  font-size: 11px;
  border: 2px solid #6d6e71;
}
.light-button:focus,
.light-button:hover {
  background-color: #6d6e71;
  border-color: #6d6e71;
  padding: 3px 0px;
  font-size: 11px;
}

.light-button:disabled {
  border-color: #6d6e71;
}

.light-button3 {
  background-color: #6d6e71 !important;
  border-color: #6d6e71;
  padding: 7px 20px;
  font-size: 12px;
  border: 2px solid #6d6e71;
  color: white;
}
.light-button3:focus,
.light-button3:hover {
  background-color: #6d6e71;
  border-color: #6d6e71;
  padding: 7px 20px;
  font-size: 12px;
  border: 2px solid #6d6e71;
  color: white;
}

.light-button3:disabled {
  border-color: #6d6e71;
  padding: 7px 20px;
  color: white;
}

.btn-ss,
.btn-ss:hover {
  border-color: #6d6e71 !important;
  background: #6d6e71 !important;
}
.label {
  color: #fff;
  font-size: 12px;
}
.form-title1 {
  font-size: 20px !important;
  color: #ff3751;
}
.w-100 {
  width: 100%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.inner-box {
  max-width: 400px;
  margin: 0 auto;
}
.text-sm {
  color: #ff3751;
  font-size: 8px;
  text-decoration: none;
}
.input-ext {
  width: 100px;
}
.lang {
  color: #ff3751;
}
.input-group-addon {
  border: 2px solid #f2405f;
  border-radius: 11px 11px 10px 10px;
  border-right: 0;
}
.input-group-addon i {
  padding-bottom: 0;
  padding: 0 10px;
  color: #fff;
  border: none;
}
.input-group-addon-r {
  border: 2px solid #f2405f;
  border-radius: 11px 11px 10px 10px;
  border-left: 0;
}
.input-group-addon-r i {
  padding-bottom: 0;
  padding: 0 10px;
  color: #61e896;
  border: none;
}
.addon-input-r {
  border-right: 0;
  border-left: 0;
}
.addon-input {
  border-left: 0;
}
.addon-grp {
  display: flex;
  align-items: center;
}
.input:focus {
  background-color: transparent;
  color: #fff;
  outline: none;
  box-shadow: none;
  border-color: #f2405f;
}
.addon-grp select {
  background-color: transparent !important;
  color: #fff;
  border: none;
  border-right: 2px solid #f5405f;
  padding-left: 5px;
}

.custom-s2 {
  border-radius: none !important;
  height: 20px !important;
}

.eye-1 {
  border-right: 2px solid #f5405f;
}
.checkbox label:after {
  content: "";
  display: table;
  clear: both;
}
.checkbox {
  position: relative;
}
.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #96a0a8;
  border-radius: 0;
  width: 15px;
  height: 15px;
  float: left;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 15%;
}

.checkbox label input[type="checkbox"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
  opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

/*.checkbox-label {
  position: absolute;
  top: 5px;
}*/
select {
  font-size: 12px;
}

.select-cc {
  max-width: 100px;
}
.note {
  color: #fff;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
}
.eye-icon {
  cursor: pointer;
  font-size: 20px !important;
}
.light-text {
  color: #6d6e71;
  text-align: right;
  margin-bottom: 0;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}
.step-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #6d6e71 !important;
  border: 1px solid #f5405f;
  transition: 0.4s;
  color: #fff;
  font-size: 13px;
}
.step-button[aria-expanded="true"] {
  background-color: #212529;
  color: #fff;
}
.previous-step {
  background-color: #ff3751 !important;
}
.step-button.current-step {
  background-color: #151726 !important;
  color: #fff;
}

.step-item.active-step {
  font-weight: bold;
}
.step-button.next-steps {
  background-color: yellow;
}
.step-item .btn {
  --bs-btn-padding-x: auto !important;
}
.done {
  background-color: #f5405f;
  color: #fff;
}
.steps-container {
  width: 100%;
  position: relative;
}

.step-item {
  z-index: 10;
  text-align: center;
}
#progress {
  position: absolute;
  width: 80%;
  z-index: 5;
  height: 1px;
  margin-left: 18px;
  margin-bottom: 18px;
  bottom: 14px;
}
/* to customize progress bar */
#progress::-webkit-progress-value {
  background-color: #f5405f;
  transition: 0.5s ease;
}
#progress::-webkit-progress-bar {
  background-color: red;
}

.step-progress {
  position: absolute;
  top: calc(50% + 10px);
  width: 86%;
  left: 0;
  margin-left: 30px;
  z-index: 5;
  height: 3px;
  background: #ffffff50;
}

/* to customize progress bar */
.step-progress .step-progress-value {
  background: #f54060;
  height: 100%;
  width: 0;
  transition: 0.5s width ease-in-out;
}
.step-title {
  color: #fff;
  font-size: 10px;
  margin-bottom: 10px;
}
.number {
  border-bottom: 1px solid #f5405f;
  padding-bottom: 10px;
  font-size: 16px;
}
.card-title {
  font-size: 16px;
  letter-spacing: 1px;
  color: #414042;
}
.card-type {
  color: #414042;
  font-size: 10px;
  letter-spacing: 1px;
}
.account-box {
  display: flex;
}
.account {
  width: 50%;
  margin: 0 5px;
}
.account2 {
  width: 33%; /* Adjusted width to display three cards in a row */
  margin: 0 5px;
}
.account2 .card {
  border-radius: 17px;
  background-color: #73747d;
}
.account2 .icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #e45163;
}
.account2.animated {
  transition: 0.3s transform;
}
.account2.animated:hover {
  transform: scale(1.15);
}
.account.animated {
  transition: 0.3s transform;
}
.account.animated:hover {
  transform: scale(1.15);
}
.rounded-button {
  border-radius: 14px;
}
.abs-btn {
  padding: 11px 10px;
  font-size: 12px;
  bottom: -44px;
  letter-spacing: 1px;
  position: absolute;
  left: -2;
  right: 0;
  margin: 0 8px;
  width: 90;
}
.abs-btn2 {
  padding: 11px 10px;
  font-size: 12px;
  bottom: -44px;
  letter-spacing: 1px;
  position: absolute;
  left: 10px;
  right: 0;
  margin: 0 8px;
  /* width: 90%; */
}
.abs-btn3 {
  padding: 11px 10px;
  font-size: 12px;
  bottom: -35px;
  letter-spacing: 1px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 8px;
  width: 90%;
}
.rounded-button:hover {
  background-color: #ff3751;
}
.rounded-button:disabled {
  background-color: #73747d;
  border-color: #73747d;
}
.rounded-button:disabled:hover {
  background-color: #73747d !important;
  border-color: #73747d !important;
}
.account .card {
  border-radius: 17px;
  background-color: #73747d;
}
.account .icon {
  font-size: 40px;
  margin-bottom: 10px;
  color: #e45163;
}

/* active account */
.active-account .card {
  background-color: #e0e1e2;
}
.active-account {
  width: 70%;
}
.active-account .card-title {
  font-size: 21px;
}
/* active account */
.active-account .card-type {
  font-size: 10px;
}
.account .card-body {
  position: relative;
}
.active-account .rounded-button {
  font-size: 18px;
}
.account.active-account .icon {
  font-size: 60px;
  color: #ff3751;
}
/* active account */
.balance-title {
  color: #ff3751;
  letter-spacing: 4px;
  font-size: 14px;
}
.balance-value {
  margin-top: 10px;
  font-size: 39px;
  color: #ff3751;
  font-weight: 600;
}
.bg-white {
  background-color: #fff;
}
.active-account .coin-btn {
  font-size: 15px;
}
.rounded-button.coin-btn {
  bottom: -23px;
  left: 3px;
}
.balance-value p:first-child {
  border: 1px solid #ff3751;
  border-radius: 50%;
  font-size: 17px;
  width: 30px;
  height: 30px;
  line-height: 27px;
}

.balance-value p {
  color: #ff3751;
  font-size: 30px;
  margin-bottom: 0;
}
.qr-link {
  font-size: 12px;
}
.text-dim {
  color: #6d6e71;
  font-weight: 600;
}
.qr-title {
  font-size: 22px;
}
.qr-subtitle {
  font-size: 13px;
}
.gallery-title {
  font-size: 18px;
}
.balance-para {
  font-size: 15px;
  line-height: 20px;
}

/*  */
/* carousel  */
.carousel-item {
  max-height: 450px;
}
.carousel-item img {
  max-height: 450px;
}

.carousel-instant-post img {
  height: 650px;
  width: 100%;
}

/* photos */
.custom_photo {
  width: 100%;
  height: 300px;
  max-height: 300px;
}
.custom_photo1 {
  width: 100%;
  height: 450px;
  max-height: 450px;
  border-radius: 10px;
}

.user-profile-image {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-top: 15px;
  margin-left: 22px;
}

/* .pagination */

.pagination {
  background: #e8e8e8;
  color: #ff3751;
  border-radius: 10px;
}

.page-item {
  border-radius: 10px;
}
.pagination .page-link {
  background: #e8e8e8;
  color: #ff3751;
  /* border-color: #; */
}
.pagination .page-link:focus {
  outline-color: none;
  outline: none;
}
.pagination .active > .page-link,
.page-link.active {
  color: #fff;
  background-color: #ff3751;
  border-color: #ff3751;
}
.pagination .disabled > .page-link,
.page-link.disabled {
  color: #ffffff3b;
  background-color: #2d2e38 !important;
  border-color: #2d2e38 !important;
}

@media only screen and (min-width: 1025px) and (max-width: 1920px) {
  .preview-card-container {
    background-size: 275px 400px !important;
    margin-top: -5px;
    margin-left: -2px;
  }
  .preview-card-img {
    height: 390px !important;
    width: 270px !important;
    max-width: 270px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .preview-card-img,
  .preview-card-container {
    height: 350px !important;
    width: 250px !important;
    max-width: 250px !important;
  }
  .preview-card-container {
    height: 400px !important;
  }

  .preview-card-img {
    max-width: 250px !important;
    height: 355px !important;
  }
  .bg_p1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .bg_p1 .form-check {
    height: 20px !important;
  }

  .bg_p1 .form-check-input {
    height: 15px;
    width: 15px;
    margin-top: 7px !important;
  }
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }

  .form-title1 {
    font-size: 16px !important;
    color: #ff3751;
  }

  .carousel-instant-post .carousel-item img {
    height: 400px !important;
    width: 100% !important;
  }
  /* carousel  */
  /* .carousel-item {
    max-height: 250px !important;
  }
  .carousel-item img {
    max-height: 250px !important;
  } */

  .custom_photo {
    width: 100%;
    height: 180px;
    max-height: 180px;
  }
  .custom_photo1 {
    height: 250px;
    max-height: 250px;
  }
  /* .preview-card-img {
    height: 250px !important;
    width: 100% !important;
  }
   */
  .preview-card-img {
    height: 250px !important;
    max-height: 250px;
    width: 120px !important;
    max-width: 120px;
  }
  .preview-card-container {
    height: 250px !important;
    width: 120px !important;
    max-width: 120px;

    background-image: url("../assets/imgs/card-fram-1.png") !important;
    background-size: 100% 100% !important;
  }

  .preview-card-img {
    max-width: 120px;
  }

  .prview-tchat-logo {
    text-align: 12px;
  }
  .prview-tchat-logo img {
    width: 20px !important;
  }
  .prview-tchat-logo {
    font-size: 10px !important;
  }

  .preview-flowers {
    width: 35px !important;
    height: 35px !important;
  }

  .prview-tele-div i {
    font-size: 45px !important;
  }
  /*  */

  .user-profile-image {
    width: 100px;
    height: 100px;

    margin-top: 15px;
    margin-left: 5px;
  }
  .clt-img-profile-sm-2 img {
    width: 40px !important;
    height: 40px !important;
  }

  .clt-img2 {
    width: 140px !important;
    height: 140px !important;
    margin-top: -120px !important;
    margin-left: -15px;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */

@media (min-width: 768px) {
  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

.gallery-box {
  border: 1px solid #f8405f;
  border-radius: 10px;
}

.progress {
  width: 22px;
  height: 22px;
  font-size: 2px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #07070c;
  text-align: center;
  line-height: 30px;
}

.progress-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progress-step .inner {
  display: flex;
  align-items: center;
  gap: 20px;
}
.progress::after {
  content: "%";
}

.progress .title {
  position: relative;
  z-index: 100;
}

.progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #07070c;
}
.progress-complete {
  color: #ff3751;
  font-size: 20px;
}
.progress-pending {
  font-size: 20px;
  color: #6d6e71;
}
.progress .left,
.progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid #ff3751;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress .left {
  animation: load1 1s linear forwards;
}

.progress:nth-of-type(2) .right,
.progress:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress:last-of-type .right,
.progress:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}
.l-1 {
  letter-spacing: 1px;
}
.modal-title {
  font-size: 16px;
}
.modal-footer {
  border-top: none;
  display: contents;
}
.modal-footer button {
  display: block;
}
.modal-content {
  padding: 50px 36px;
  border: 2px solid #ff3751;
  border-radius: 14px;
  background-color: #151726;
}
.modal-dialog.modal-lg {
  max-width: 1000px;
  margin-top: 0px;
}
.modal-dialog {
  max-width: 380px;
}
.d-input {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  border-radius: 20px;
  height: 30px;
}
.input-md {
  height: 32px;
}
.input-sm {
  height: 29px;
}
.check-box {
  background-color: #f8405f;
  border-radius: 20px;
  padding: 0 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}
.msg-box {
  border-bottom: 2px solid #f8405f;
}
.msg {
  font-size: 12px;
  text-align: justify;
  padding-right: 5px;
}
.msg-datetime {
  color: #6d6e71;
  font-size: 11px;
}
.msg-close {
  color: #6d6e71;
  font-size: 12px;
  line-height: 21px;
}
.msg-datetime-box {
  position: relative;
  min-width: 50px;
  flex-direction: column-reverse;
}
.fa-eye-slash {
  color: #6d6e71;
}
.unread {
  position: absolute;
  font-size: 12px;
  right: 0;
  top: 5px;
}
.no-decoration {
  text-decoration: none;
}
.post-box {
  background: #f8405f;
  border-radius: 6px;
  padding: 1px 5px;
  position: relative;
}
.post-box input,
.post-box textarea {
  background-color: transparent;
  border: none;
  color: #fff;
  border-radius: 0;
}
.post-box input {
  font-weight: 500;
  font-size: 14px;
  border: none !important;
  border-bottom: 1px solid #fff !important;
}
.post-box input:hover {
  border: none !important;
  border-bottom: 1px solid #fff !important;
}
.post-box textarea {
  font-weight: 300;
  font-size: 10px;
  resize: none;
}
.post-box .ACD {
  min-height: 70px !important;
}
.post-box textarea {
  min-height: 100px !important;
}
.post-box input::placeholder,
.post-box textarea::placeholder {
  color: #fff;
}
.post-box input:focus,
.post-box textarea:focus {
  background-color: transparent;
  -webkit-box-shadow: none;
  border-color: #fff;
  color: #fff;
}

#count_message {
  font-size: 8px;
  position: absolute;
  right: 7px;
  bottom: 4px;
  background: #f8405f;
  padding: 5px;
}
#count_message_title {
  font-size: 8px;
  position: absolute;
  right: 7px;
  top: 18px;
}

.pointer {
  cursor: pointer;
}
.post-select {
  background: #f8405f;
  border-radius: 6px;
  outline: none;
  border: 1px solid #f8405f;
  color: #fff;
  font-size: 12px;
  padding: 4px 20px;
}
.preview {
  font-size: 16px;
  margin: 10px 0;
}
.post-video p {
  font-size: 14px;
  color: #96a0a8;
}
.video-player {
  max-width: 100% !important;
  border-radius: 10px !important;
  max-height: 300px !important;
}
.post-video {
  width: 100%;
  border: 1px solid #f2405f;
  border-radius: 3px;
  min-height: 100px;
  border-style: dashed;
}
.video {
  width: 100%;
  border: 1px solid black;
  border-radius: 6px;
}
video::-webkit-media-controls {
  display: flex !important;
}

/* .video-div iframe {
  width: 1110px !important;
} */

.preview-video {
  max-height: 380px;
}
.wrapper {
  display: table;
  width: auto;
  position: relative;
  width: 40%;
}
.playpause {
  background-image: url("../../public/img/play.png");
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  background-size: contain;
  background-position: center;
}
.postlist-main-title {
  font-size: 16px;
  border-bottom: 2px solid #f8405f;
}
.postlist-title,
.postlist-client {
  font-size: 16px;
}
.postlist-title {
  text-decoration: underline;
}
.postlist-title a:hover {
  color: #ff3751;
}
.post-likes {
  color: #f2405f;
  font-size: 10px;
}
.post-likes-1 img {
  width: 20px;
}
.post-likes-2 {
  /* color: #f2405f;
  font-size: 10px; */
}
.post-likes-1 {
  color: #ffd900;
}
.post-saved {
  color: #fff;
  font-size: 12px;
}

.postlist-datetime h6 {
  font-size: 10px;
  color: #6d6e71;
  margin-bottom: 1px;
}
.postlist-box {
  border-bottom: 1px solid #f8405f;
  padding: 10px 5px;
}
.post-edit {
  font-size: 14px;
  font-weight: 500;
}
.create-post-btn {
  font-size: 14px;
}
.schedule-list {
  font-size: 14px;
  font-weight: 500;
}

/******Sujoy***********/
header {
  position: relative;
}
.lft-arw {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}
.clk-btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}
.stn-btn {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  text-align: center;
}
.stn-btn i {
  position: absolute;
  left: 52%;
  top: 54%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
.stn-btn i.fa-comment {
  font-size: 35px;
}
.stn-btn span {
  background: #fff;
  line-height: 20px;
  font-size: 10px;
  position: absolute;
  right: -10px;
  top: -6px;
  border-radius: 50%;
  color: #f5405f;
  width: auto;
  height: 20px;
  min-width: 20px;
  /* font-weight: bold; */
  /* border-color: #d79500; */
}
.rgd-mob {
  color: #fff;
  letter-spacing: 1px;
  border-bottom: 1px solid #f5405f;
}
.rgd-mob span {
  display: block;
  font-size: 15px;
  font-weight: bold;
  color: #dddedf;
}
.rgd-mob a {
  display: block;
  font-size: 13px;
  color: #f5405f;
  text-decoration: underline;
}
.clt-img-profile {
  width: 10px;
  height: 100px;
  border-radius: 50%;
}
.clt-img-profile img {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 2px solid #f5405f;
}
.clt-img-profile-sm {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.clt-img-profile-sm img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 1px solid #f5405f;
}
/* .clt-img-profile-sm-2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
} */
.clt-img-profile-sm-2 img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 1px solid #f5405f;
}
.clt-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 2px solid #f5405f;
}
.clt-img1 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  border: 1px solid #f5405f;
}
.clt-img2 {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  /* border: 1px solid #f5405f; */
}
.clt-img img {
  width: 100%;
}
.pro-edt .qr-title {
  margin-right: 20px;
  color: #f5405f;
  display: inline-block;
}
.pro-edt .button i {
  margin-right: 10px;
}
.profile-title,
.profile-n {
  font-size: 12px;
}
.exc-btn {
  border: 1px solid #f5405f;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  margin: 8px 10px 0 0;
  font-size: 10px;
  color: #f5405f;
  line-height: 18px;
  background: none;
}
.popover .popover-arrow {
  display: none;
}
.popover {
  background: none;
  border: none;
}

.close-popover {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding-bottom: 3px;
  padding-top: 0px;
  position: absolute;
  margin-top: -35px;
  right: 0;
  background-color: #fff;
  color: gray;
  border: none;
  font-size: 20px;
}
.close-popover:hover {
  color: #fff;
  background-color: #ff3751;
}
.popover-body {
  background: #151726;
  font-size: 12px;
  border: 2px solid #ff3751;
  color: #fff;
  border-radius: 20px;
}
.t-chat {
  border: 1px solid #f5405f;
  border-radius: 10px;
  color: #f5405f;
  display: inline-block;
  font-size: 10px;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
  text-align: center;
}
.t-chat:hover {
  color: #fff;
  background: #ff3751 !important;
}
.t-chat:hover .default-logo {
  content: url("../../public/img/tchat_newlogo_white.png");
}
.t-chat i {
  font-size: 14px;
}
.t-chat span {
  background: #f5405f;
  line-height: 20px;
  font-size: 10px;
  position: absolute;
  right: -10px;
  top: -6px;
  border-radius: 50%;
  color: #000;
  width: 20px;
  height: 20px;
}
.chat-count {
  height: 20px;
  min-width: 20px;
  font-size: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message.sender {
  display: flex;
  flex-direction: row-reverse;
}

.message.sender .message-profile-photo {
  margin-left: 15px;
  margin-right: 0;
}

.message-content {
  margin-bottom: 20px;
}
.message.sender .message-content {
  background-color: #ff3751;
  color: #fff;
  border-radius: 10px;
  padding: 8px 12px;
  margin-left: 10px;
  margin-right: 0;
  position: relative;
}

.message.sender .message-content::before {
  content: "";
  position: absolute;
  /* bottom: 10px; */
  right: -8px;
  /* bottom: 50%; */
  top: 15px;
  transform: translateY(50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff3751;
}

.message.receiver {
  display: flex;
  flex-direction: row;
}

.message.receiver .message-profile-photo {
  margin-right: 10px;
  margin-left: 0;
}

.message.receiver .message-content {
  background-color: #414042;
  color: #fff;
  border-radius: 10px;
  padding: 8px 12px;
  margin-right: 10px;
  margin-left: 5px;
  position: relative;
  top: 0;
}

.message.receiver .message-content::before {
  content: "";
  position: absolute;
  /* bottom: 50%; */
  left: -8px;
  transform: translateY(50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #414042;
}

.bg-dark2 {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-dark3 {
  background: #1d1d1d;
}
.plan-wrap {
  max-width: 400px;
  margin: 0 auto;
}
.plan-wrap .exc-btn {
  margin: 8px 0 0 10px;
}
.plan-wrap a.his-btn {
  display: block;
  color: #f5405f;
  text-decoration: underline;
  font-size: 12px;
}
.plan-wrap .btn:hover {
  color: #fff;
}
.plan-wrap .btn:active {
  color: #fff;
  background: none;
  border-color: #fff;
}
.plan-wrap .btn:focus {
  color: #fff;
  border-color: #fff;
}
.plan-wrap .btn:focus option {
  background: #f5405f;
}
.plan-wrap .btn2,
.btn2:hover {
  background: #6d6e71 !important;
  /* border: 2px solid #6d6e71 !important; */
}

.plan-wrap hr {
  background: #ff3751;
  border: none;
  opacity: 1;
  height: 2px;
  margin: 40px 0 0 0;
}
.account .exc-btn {
  position: absolute;
  right: 10px;
  top: 0;
  margin: 0;
}
.account h4 {
  color: #ff3751;
}
.navigation {
  background: #151725;
  position: fixed;
  right: -340px;
  top: 0;
  height: 100%;
  z-index: 1;
  padding: 30px;
  width: 340px;
  overflow-y: auto;
  z-index: 10;
}
.navigation ul {
  list-style: none;
  position: relative;
  padding: 0;
  margin: 50px 0;
  padding-bottom: 50px;
  border-bottom: 1px solid #f5405f;
}
.navigation ul li {
  margin-bottom: 30px;
}
.navigation ul li:last-child {
  margin-bottom: 0;
}
.navigation ul li a {
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.navigation ul li a i {
  font-size: 22px;
  margin-right: 10px;
  color: #f5405f;
}
.navigation ul li a img,
.navigation ol li a img {
  width: 24px;
  margin-right: 10px;
}
.navigation ul li a span {
  position: relative;
}
.navigation ul li a:hover {
  color: #f5405f;
}
.navigation ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.navigation ol li a {
  text-decoration: none;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.navigation ol li:last-child a {
  color: #f5405f;
}

.navigation ol li {
  margin-bottom: 20px;
}
a.close-menu {
  position: fixed;
  right: 30px;
  top: 50px;
  font-size: 30px;
}
a.close-menu:hover {
  color: #ff3751;
  cursor: pointer;
}
.check-box {
  position: relative;
}
.check-box input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.check-box input[type="checkbox"] + label {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding-left: 30px;
  pointer-events: none;
  line-height: 26px;
}
.check-box input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 50%;
  border: 1px solid #fff;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}
.check-box input[type="checkbox"]:checked + label:after {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 14px;
}
.nat-box .exc-btn {
  margin: 0;
}
.oh-date input {
  color: #fff;
  text-align: center;
  line-height: 40px;
}
.oh-date input[type="reset"] {
  border-radius: 20px;
  line-height: 32px;
  padding: 0;
  border: none;
  font-size: 16px;
}
.map {
  overflow: hidden;
  margin: 50px 0;
  border-radius: 5px;
}
.map iframe {
  width: 100%;
  height: 300px;
}
.d-input {
  padding: 10px;
  color: #fff;
}
.ad-video {
  width: auto;
}
.video {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #831427;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 4px;
  bottom: 1px;
  background-color: #ff3751;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider-active {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #831427;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-active:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 4px;
  bottom: 1px;
  background-color: #00a67e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  line-height: 34px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.slider.round:before {
  border-radius: 50%;
}
.slider-active.round {
  border-radius: 34px;
  line-height: 34px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.slider-active.round:before {
  border-radius: 50%;
}
.table-wrap {
  background: #151726;
  padding: 20px 0;
  border-radius: 30px;
}
.table-wrap th {
  color: #b3c1ce;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}
.table-wrap td {
  color: #b3c1ce;
  text-align: center;
  font-size: 14px;
}
.table-wrap td span {
  display: block;
  font-size: 12px;
}
.table-wrap td a {
  color: #b3c1ce;
  text-decoration: none;
}
.table-wrap tr {
  border-color: #ff3751;
}
.date-box .input-group-text {
  background: none;
  border: none;
  color: #ff3751;
  cursor: pointer;
}
.date-box .form-control {
  background: none;
  border: none !important;
  color: #ff3751;
}
.date-box {
  border: 2px solid #ff3751;
  border-radius: 10px;
}
.text-scheduled {
  font-size: 12px;
}
.primary-button.gr-btn {
  background-color: #61e593 !important;
  border: none;
  pointer-events: none;
  /*padding: 13px 30px;*/
}
.gr-btn1 {
  background-color: #61e896 !important;
  border: none;
  padding: 10px 40px;
  border-radius: 35px;
  font-size: 12px;
}
.nav-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;
  padding: 0;
}
.nav-page li {
  width: 30%;
  cursor: pointer;
  list-style: none;
}
.nav-page li::marker {
  content: "";
}
.nav-page li a {
  background: #e8e8e8;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  color: #151726;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.nav-page li a:after {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.nav-page li a i {
  color: #ff3751;
  font-size: 20px;
  margin-right: 5px;
}
.nav-page li a img {
  width: 20px;
  margin-right: 5px;
}
.nav-page li.active a:after {
  display: none;
}
.account.active-account .icon {
  font-size: 30px;
  margin: 0 10px 0 0;
}
.addon-grp select,
.addon-grp .xtn {
  background-color: transparent;
  color: #fff;
  border: none;
  border-right: 2px solid #f5405f;
  padding-left: 5px;
}
.addon-grp .xtn {
  font-size: 12px;
  width: 40px;
  padding: 0 8px;
}
.abs-btn:hover {
  color: #fff;
}
.d-select {
  background-color: #ff3751;
  border: 1px solid #ff3751;
  border-radius: 20px;
  color: #fff;
  padding: 0 10px;
  font-size: 16px;
}
.plan-wrap .account .card {
  background-color: #e0e1e2;
}
.oh-date.active input {
  background-color: #6d6e71;
  border-color: #6d6e71;
}
.oh-date.active input.rest-btn {
  background-color: #f8405f;
}

header .title img {
  width: 28px;
  margin-right: 10px;
}
.shorting-top {
  padding: 0;
  margin: 10px 15px;
  color: #fff;
  list-style: none;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shorting-top li a {
  display: inline-block;
  margin-left: 5px;
}

.modal-dialog {
  margin-top: 150px;
}
.btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  top: -33px;
  background-size: 10px;
}
.modal-content.rfp {
  padding: 10px;
}

.reload-flower-wrap strong {
  display: flex;
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
  justify-content: flex-end;
  align-items: center;
}
.modal-body i.fa-dollar-sign {
  background: #fff;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  color: #000;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  position: relative;
  margin: 0 8px;
}
.modal-body i.fa-dollar-sign:after {
  content: "";
  position: absolute;
  border: 1px solid #000;
  right: 2px;
  top: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 50%;
  pointer-events: none;
}

.qty-box > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  margin: 0 auto 10px auto;
}
.qty-box button {
  background: #414042;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
}
.qty-box input[type="text"] {
  background: #414042;
  border: none;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  margin: 0 10px;
}
.modal-body .qty-box i.fa-dollar-sign {
  margin: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.reload-flower-wrap h5 {
  color: #ff3751;
  font-size: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reload-flower-wrap h5 img {
  width: 40px;
  margin-right: 10px;
}
.reload-flower-wrap h5 i {
  margin-right: 10px;
  font-size: 50px;
}
.reload-flower-wrap h5 span {
  color: #fff;
  font-size: 14px;
  width: 130px;
  text-align: left;
  margin-left: 10px;
  text-transform: uppercase;
  font-weight: 400;
}
.reload-flower-wrap .rounded-button.coin-btn {
  bottom: auto;
  position: relative;
  margin: 20px 0;
}
.reload-flower-wrap a.rf-btn {
  display: inline-block;
  text-decoration: none;
}
.rf-back {
  display: none;
}

.request-btn {
  background: #ff3751;
  border: 1px solid #ff3751;
  color: #fff;
  border-radius: 15px;
  /* line-height: 26px; */
  font-size: 12px;
  width: 100%;
  padding: 5px;
}
.request-btn:hover {
  background: #151726;
  border: 1px solid #ff3751;
  color: #fff;
}
.request-btn.f-btn {
  background: none;
}
.table-wrap td a.ig-btn {
  /* color: #ff3751; */
  color: white;
  font-size: 26px;
}
.account img {
  width: 50px;
  margin-bottom: 10px;
}
.mf .qr-title {
  margin-right: 0;
}
.mf h5 {
  font-size: 12px;
  font-weight: 400;
}
.mf ul {
  padding: 0;
  margin: 10px 0 0 0;
  list-style: none;
  color: #d79500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mf ul li {
  margin: 0 5px 0 0;
}
.flower-box {
  background: #fff;
  margin: 0 20px;
  border-radius: 10px;
  padding: 10px 10px 20px 10px;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 50%);
}
.flower-box ul {
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flower-box ul li span {
  color: #ff3751;
  letter-spacing: 4px;
  font-size: 14px;
  display: block;
}
.flower-box ul li strong {
  display: flex;
  font-size: 40px;
  color: #ff3751;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}
.flower-box ul li strong img {
  width: 50px;
  margin-right: 10px;
}
.flower-box2 {
  background: #e2e2e2;
  margin: 0 10px;
  border-radius: 10px;
  padding: 10px 10px 20px 10px;
  box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 50%);
  overflow: hidden;
  margin-top: -6px;
}
.flower-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flower-top h3 {
  color: #414042;
  letter-spacing: 4px;
  font-size: 14px;
  display: block;
  margin: 0;
}
.flower-top h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff3751;
  margin: 0;
}
.flower-top-balacnce {
  font-size: 40px !important;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff3751;
  margin: 0;
}
.flower-top-img img {
  max-width: 28px;
  width: 28px;
}
.flower-top h4 span {
  font-size: 14px;
}
.flower-top h4 img {
  width: 20px;
}
.flower-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
}
.flower-bottom h5 {
  font-size: 12px;
  color: #414042;
  margin: 0;
  text-align: right;
  letter-spacing: 4px;
  text-transform: uppercase;
  width: 150px;
}
.flower-bottom h6 {
  font-size: 44px;
  color: #414042;
  margin: 0;
  line-height: normal;
}
.f-qty {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
}
.buy-btn-top {
  height: 50px;
}
.f-qty button {
  background: none;
  border: 2px solid #ff3751;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ff3751;
}
.f-qty input[type="text"] {
  background: #212529 url(../../public/img/flower_icon.png) no-repeat;
  border: none;
  width: 70px;
  height: 30px;
  border-radius: 20px;
  color: #fff;
  margin: 0 5px;
  padding: 0 20px;
  font-weight: 600;
  background-position: right 10px center;
  background-size: 16px auto;
}
.f-qty-2 input[type="text"] {
  background: #212529 no-repeat;
  margin-left: 10px;
  padding: 0px;
  width: 30;
}
.f-qty div {
  background: #212529 no-repeat;
  border: none;
  width: 70px;
  height: 30px;
  border-radius: 20px;
  color: #fff;
  margin: 0 5px;
  padding: 0 40px;
  font-weight: 600;
  background-position: right 10px center;
  background-size: 16px auto;
}
.flower-box2 .primary-button {
  float: right;
  font-size: 12px;
  text-transform: uppercase;
  /* border-radius: 5px; */
}
.flower-box2 + .flower-box2 {
  margin-left: 0;
  margin-right: 0;
}
.reload-flower-wrap h3 {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 10px 0;
}
.reload-flower-wrap h4 {
  color: #ff3751;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
}
.reload-flower-wrap h4 span {
  color: #fff;
}
.reload-flower-wrap h4 sub {
  vertical-align: top;
}
.reload-flower-wrap h2 {
  color: #ff3751;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
}
.reload-flower-wrap h2 strong {
  display: inline-block;
  font-size: 50px;
  color: #ff3751;
  margin: 0 5px 0 0;
}
.reload-flower-wrap h2 span {
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
.reload-flower-wrap h2 sub {
  vertical-align: top;
  bottom: auto;
  top: -10px;
}

.rank-title {
  color: #ff3751;
  letter-spacing: 4px;
  font-size: 14px;
  display: block;
  margin: 50px 0 20px 0;
}
.rank-title img {
  width: 30px;
  margin-right: 5px;
}
.rank-box {
  background: #ff3751;
  padding: 30px 10px 10px 10px;
  border-radius: 5px;
  position: relative;
  color: #fff;
}
.rank-box .clt-img {
  border-color: #fff;
  margin-bottom: 10px;
}
.ranking {
  position: absolute;
  right: 5px;
  top: 5px;
}
.ranking i {
  color: #d79500;
  font-size: 20px;
}
.ranking a {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 26px;
  font-weight: 700;
  color: #151726;
  text-decoration: none;
}
.rank-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rank-top ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 38px;
}
.rank-top ul li {
  display: inline-block;
  font-size: 8px;
}
.rank-top strong {
  font-weight: 400;
  font-size: 14px;
}
.rank-top strong img {
  width: 20px;
}
.rank-top .fa-crown {
  color: #d79500;
}
.rank-box.rank-box hr {
  background: #fff;
  border: none;
  height: 1px;
  margin: 10px 0;
}
.rank-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rank-btm span {
  font-size: 10px;
}
.preview-box {
  color: #ff3751;
}
.preview-box .clt-img {
  width: 117px;
  height: 117px;
}
.preview-box span {
  display: block;
  text-align: center;
  margin: 10px 0;
}
.preview-box strong {
  display: block;
  text-align: center;
  font-weight: 400;
  letter-spacing: 2px;
}
.preview-box h2 {
  font-size: 24px;
  line-height: 44px;
  color: #ff3751;
}
.preview-box h2 a {
  color: #ff3751;
}
.preview-box .btn {
  background: none;
  border: 2px solid #ff3751;
}
.preview-box p {
  color: #fff;
  font-size: 10px;
  text-align: center;
  margin: 5px 0 0 0;
}
.send-flower {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.send-flower img {
  width: 40px;
  margin: 0 10px;
}
.send-flower .rounded-button {
  margin-left: 10px;
}
.clnt-dtl {
  list-style: none;
  padding: 10px 0 20px 0;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  color: #fff;
  border-bottom: 1px solid #ff3751;
  font-weight: 400;
  font-size: 12px;
}
.clnt-dtl li {
  border-right: 1px solid #fff;
  padding: 5px 12px;
  text-align: center;
  width: 100%;
}
.clnt-dtl li:last-child {
  border-right: none;
}
.clnt-dtl li span {
  color: #ff3751;
  display: block;
  margin-bottom: 10px;
}
.clnt-dtl li p {
  text-align: left;
}
.clnt-dtl li h5 {
  font-size: 30px;
}

.wel-box p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
}
.wel-box p span {
  font-size: 12px;
  color: #ff3751;
  margin-right: 10px;
}
.wel-box p strong {
  font-size: 12px;
  font-weight: normal;
}
.pro-edt p {
  text-align: right;
  font-size: 12px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
}
.pro-edt h6 {
  margin: 30px 0;
}
.pro-edt .fa-phone {
  font-size: 30px;
}
div#tab-wrap {
  margin-top: 40px;
}
.tab-group {
  display: flex;
  justify-content: start;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  /* gap: 10px; */
  /* margin: 0 0 -1px 0; */
}
.tab-group li {
  margin: 0px;
  width: auto;
  padding: 2px;
}
.tab-group li a {
  border: 1px solid #ff3751;
  margin: 0 1px;
  display: block;
  border-radius: 8px;
  cursor: pointer;
  color: #fff !important;
  text-align: center;
  padding: 10px;
  font-size: 14px;
}
.tab-group li.active a {
  background: #ff3751;
}
.tab-block {
  /* border: 1px solid #ff3751; */
  padding: 10px;
  border-radius: 10px;
}
.tab-block img {
  border-radius: 10px;
}
a.photo-box {
  display: block;
  position: relative;
}
a.p-play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(245, 64, 95, 0.8);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
a.p-play-btn:before {
  content: "\f04b";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: #fff;
  z-index: 1;
}
.popover-list {
  list-style-type: none;
  padding-left: 0;
}
.popover-list li {
  display: flex;
  align-items: baseline;
  gap: 6px;
  margin-bottom: 10px;
}
.popover-btn {
  background: #f8405f 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-size: 6px;
  color: #fff;
  padding: 3px 10px;
  border: none;
  outline: none;
}
.popover-list li .fa {
  background: #f8405f 0% 0% no-repeat padding-box;
  border-radius: 9px;
  color: #fff;
  font-size: 10px;
  padding: 3px 5px;
}
.blog-popover {
  padding: 20px;
}
.blog-popover .title {
  margin: 0 auto;
  margin-bottom: 15px;
}
.blog-popover .subtitle {
  color: #f5405f;
  font-size: 10px;
  margin-bottom: 25px;
  letter-spacing: 0.55px;
}
.blog-popover p {
  font-size: 12px;
  margin-bottom: 25px;
}
.addon-grp select option {
  background-color: #151726;
}
.checkbox label input[type="checkbox"] {
  display: block;
  margin-right: 10px;
}
.checkbox label {
  display: flex;
}
a {
  text-decoration: none;
}
.secondary-button:hover,
.secondary-button:focus-visible {
  border: 2px solid #f2405f;
}
.primary-button:hover,
.primary-button:focus-visible {
  background-color: #ff3751;
  color: #fff;
}
.primary-button.button.light-button {
  padding: 7px 20px;
}
.mir-wrap {
  margin-bottom: 10px;
}
.mir-left {
  text-align: center;
}
.mir-left ul {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #fff;
}
.mir-left ul li {
  padding: 0 5px;
  position: relative;
}
.mir-left ul li:after {
  content: "";
  background: #f5405f;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 40px;
  width: 1px;
  pointer-events: none;
}
.mir-left ul li:last-child:after {
  display: none;
}
.mir-left ul li strong {
  color: #f5405f;
  line-height: 10px;
  display: block;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}
.mir-left ul li .ranking {
  position: relative;
  right: auto;
  top: auto;
}
.mir-left ul li .ranking i {
  font-size: 35px;
}
.mir-left ul li .ranking a,
.mir-left ul li .ranking a:hover {
  color: #f00;
}
.mir-left .request-btn {
  height: 30px;
  /* line-height: 30px; */
  width: 100%;
  font-size: 12px;
}
.mir-left h6 {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
}
.mir-left .clt-img {
  margin: 0 auto;
  width: 50px;
  height: 50px;
}
.mir-right {
  border: 1px solid #f5405f;
  padding: 10px;
  border-radius: 10px;
  display: block;
}
.mir-right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
}
.mir-right ul li {
  padding: 0 5px;
}
.mir-right ul li span {
  color: #fff;
  line-height: 12px;
  display: block;
  font-size: 8px;
  font-weight: 400;
}
.mir-right ul li a {
  color: #d79500;
}
.thumbs-box {
  position: relative;
  display: inline-block;
}
.thumbs-box i {
  font-size: 30px;
  color: #fff;
}
.thumbs-box span {
  position: absolute;
  left: 11px;
  top: 5px;
  font-size: 10px;
  text-align: center;
  line-height: 26px;
  font-weight: 700;
  color: #ff3751;
  text-decoration: none;
}
.mir-right h2 {
  font-size: 16px;
  display: flex;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f5405f;
  padding-top: 10px;
  margin-top: 10px;
}
.mir-right h2 span {
  color: #898989;
  font-weight: 400;
  font-size: 10px;
}
.mir-right p {
  font-size: 12px;
  margin: 0;
  line-height: 16px;
}
.title i {
  margin-right: 5px;
}
.ip-wrap .clt-img {
  width: 60px;
  height: 60px;
  border-color: #fff;
}
.ip-wrap strong {
  font-weight: 400;
  color: #fff;
  text-decoration: underline;
}
.ip-wrap .request-btn {
  background: none;
  margin-left: 10px;
  font-size: 14px;
  color: #ff3751;
}
.ip-wrap .request-btn.f-btn {
  background: #ff3751;
  color: #fff;
}
.instant-banner {
  margin: 20px 0;
}
.instant-banner .carousel-indicators {
  right: auto;
  bottom: -40px;
  left: 50%;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  transform: translateX(-50%);
}
.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.instant-rate {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.instant-rate .ranking {
  position: relative;
  right: auto;
  top: auto;
}
.instant-rate .ranking i {
  font-size: 30px;
}
.instant-rate .ranking a {
  color: #db1134;
  font-size: 20px;
  font-weight: 700;
  line-height: 17px;
}
.instant-rate .ranking a:hover {
  color: #db1134;
}
.instant-rate i.fa-regular.fa-heart {
  color: #f5405f;
  font-size: 30px;
}
.instant-rate {
  margin-bottom: 20px;
}
.instant-title h2 {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.instant-title p {
  font-size: 14px;
  line-height: 20px;
}
.instant-title span {
  color: #898989;
  font-weight: 400;
  font-size: 14px;
}
.instant-title strong {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.instant-title {
  border-bottom: 1px solid #ff3751;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.ins-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.ins-list li {
  margin-bottom: 20px;
}
.ins-list .clt-img {
  width: 50px;
  height: 50px;
  border: none;
}
.ins-list h2 {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ins-list h2 span {
  color: #898989;
  font-weight: 400;
  font-size: 10px;
}
.ins-list p {
  line-height: 20px;
  font-size: 14px;
}
.bd-dtl {
  display: none;
}
.bd-open {
  display: block;
}
.preview a {
  color: #f5405f;
}
.c-voice .clt-img {
  width: 50px;
  height: 50px;
  border: none;
  display: inline-block;
}
.c-voice audio {
  display: inline-block;
  width: 200px;
}
.c-voice h4 {
  font-size: 16px;
}
.like-box {
  display: inline-block;
  color: #f5405f;
  position: relative;
}
.like-box i {
  font-size: 40px;
}
.like-box span {
  position: absolute;
  right: 14px;
  top: 12px;
}
.mir-box .ranking {
  position: relative;
  right: auto;
  top: auto;
}
.mir-box .ranking i {
  font-size: 40px;
}
.mir-box .ranking a {
  color: #db1134;
  font-size: 20px;
  font-weight: 700;
  line-height: 17px;
}
.mir-box ul {
  color: #fff;
  padding: 10px;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #db1134;
  border-radius: 10px;
}
.mir-box ul li span {
  color: #fff;
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}
.mir-box ul li i {
  color: #d79500;
}
.mir-box ul li .clt-img {
  width: 30px;
  height: 30px;
  border: none;
  margin: 0 auto;
}
.modal .datepicker table tr td.today:hover,
.modal .datepicker table tr td.today:hover:hover {
  background: none;
  color: #fff;
}
.modal .datepicker thead tr:first-child th:hover,
.modal .datepicker tfoot tr th:hover {
  background: none;
}
.datebox {
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #d44;
}
.datebox li {
  padding: 10px 0;
}
.datebox li:nth-child(2) {
  background: #ff3751;
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
}
.datebox li span {
  display: inline-block;
  margin: 0 20px;
}
.schedule-post {
  display: none;
}
.schedule-post h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.schedule-post h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.schedule-post strong {
  background: #ff3751;
  display: block;
  font-size: 40px;
  font-weight: 500;
  width: 100px;
  height: 100px;
  margin: 0 auto 30px auto;
  border-radius: 50%;
  color: #fff;
  line-height: 94px;
}
.schedule-post span {
  display: block;
  margin-bottom: 30px;
  font-size: 24px;
  color: #858383;
}
.u-box p {
  font-size: 12px;
  color: #6d6e71;
  margin: 0;
}
.u-box .button {
  padding: 7px 8px;
  background: none;
}
.master-img {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #ff3751;
  margin-bottom: 10px;
}
.master-account p {
  margin: 0;
  font-size: 14px;
}
.master-account h6 {
  font-size: 16px;
}
.master-account h6 span {
  color: #666;
  font-weight: 400;
}
.master-account strong {
  color: #ff3751;
  font-size: 12px;
  font-weight: 400;
  background: #414042;
  display: block;
  text-align: center;
  padding: 30px;
  border-radius: 0 0 20px 20px;
  margin-top: 20px;
}
.follower-list {
  border-bottom: none;
}
.follower-list .clt-img {
  width: 40px;
  height: 40px;
}
.follower-list h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.follower-list h5 {
  font-size: 14px;
  font-weight: 400;
  color: #ff3751;
  margin: 0;
}
.follower-list i.fa-star {
  color: #ff3751;
}
.search-client {
  border: 1px solid #ff3751;
  margin: 20px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-client input[type="search"] {
  background: none;
  border: none;
  padding: 10px;
  color: #ff3751;
}
.search-client input[type="submit"] {
  background: none;
  border: none;
  border-left: 1px solid #ff3751;
  padding: 10px;
  color: #fff;
}
ul.client-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.client-list li {
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #ff3751;
  margin: 0 0 10px 0;
}
ul.client-list li h2 {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}
ul.client-list li h3 {
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  margin: 0;
}
ul.client-list li hr {
  background: #ff3751;
  border: none;
  opacity: 1;
  height: 1px;
  margin: 30px 0;
}
ul.client-list li .btn2 {
  background: #6d6e71 !important;
}
ul.client-list li h4 {
  font-size: 14px;
  color: #ff3751;
  margin-bottom: 0;
  font-weight: 400;
}
ul.client-list li h4 img {
  width: 30px;
  margin-right: 5px;
}

.send-flower ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  color: #fff;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.send-flower ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.send-flower ul li i {
  margin-right: 10px;
  font-size: 40px;
  color: #f5405f;
}
.ac-details {
  background: #414042;
  padding: 30px 15px;
  border-radius: 0 0 20px 20px;
  border-top: 2px solid #ff3751;
  margin-bottom: 20px;
}
.ac-details h6 {
  color: #ff3751;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}
.ac-details .btn2 {
  background: #6d6e71;
}
.datepicker table tr td.active.active {
  background-color: #ff3751 !important;
}

.looking_emoji {
  width: 18px;
  height: 18px;
  object-fit: cover;
  object-position: center;
}

.dropdown-toggle::after {
  display: none !important;
}

.image-grid {
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* dropdown by R */
.fa-ellipsis-vertical {
  font-size: 16px;
  color: #ff3751;
}
.dropdown-menu {
  background: #151726;
  font-size: 12px;
  border: 1px solid #ff3751;
  color: #fff;
  border-radius: 20px;
  padding: 10px;
}
.dropdown-item {
  font-size: 14px;
  color: white;
}
.dropdown-item:hover {
  font-size: 14px;
  color: white;
  background: #ff3751;
  border-radius: 10px;
}
.dropdown-item:focus {
  color: white;
  background: #ff3751;
}

.custom-dropdown-1.btn.btn-primary {
  background-color: #151725 !important;
  border-color: #151725 !important;
}
.custom-dropdown-1.btn:disabled i {
  color: #6d6e71 !important ;
}

/* Form index.css */

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ccc;
  overflow: hidden;
  cursor: pointer;
}

.avatar img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar .initials {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.column-container {
  display: flex;
  flex-direction: column; /* Defines 3 equal-width columns */
  gap: 1px; /* Adds a 20px gap between the columns */
}
.row-container {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Adds equal spacing between the items */
}

.form-error {
  color: #ff4949;
  padding: 0;
  font-size: 13px;
}

.fixed-object {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  transition: box-shadow 0.3s;
}
.fixed-object-content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}
.image-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.scanner-wrapper {
  position: relative;
  z-index: 2;
  width: 300px;
  height: 400px;
  margin: 0 auto;
}

/* May 2023 */

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e  ");
  background-color: #ff3751;
  background-size: 10px 10px;
  background-position: right 0.6rem center;
  font-size: 12px !important;
  border-radius: 20px;
  color: white;
  height: 30px;
  border: 1px solid #ff3751;
  padding: 0 1.5rem 0 0.6rem;
  cursor: pointer;
}

.form-select:disabled {
  background-color: #6d6e71;
  border: 1px solid #6d6e71 !important ;
}

.fs-25 {
  max-width: 70px !important;
  border: 2px solid #ff3751 !important;
  background-color: #151726;
}
.fss-25 .form-select {
  max-width: 70px !important;
  border: 2px solid #ff3751 !important;
  /* background-color: transparent; */
}
.fss-25 .form-select:disabled {
  background-color: #6d6e71;
}

/* check box */

.checkbox-wrapper {
  display: inline-block;
}
.form-check-custom .form-check-input {
  background: transparent;
  border: 1px solid white;
  font-size: 12px;
}
.form-check-label {
  font-size: 12px;
}
.form-check-input:checked {
  background-color: #ff3751;
  border-color: #ff3751;
}

.bg_p1 {
  background: #ff3751;
  border-radius: 20px;
  align-items: center;
  padding: 0 5px;
  min-height: 30px;
  height: auto;
}

.form-control {
  border-radius: 20px;
  /* background: #ff3751; */
  background: transparent;
  border: 2px solid #ff3751;
  color: white;
  height: 30px;
  font-size: 12px;
}
.form-control:disabled {
  background: #595959;
}
.form-control-cus,
.form-control-cus:focus {
  background: #ff3751 !important;
  color: white;
}
.form-control:focus {
  background: transparent;
  color: white;
  border: 2px solid #ff3751;
}
.form-control-bs,
.form-control-bs:focus {
  border-left: none;
}

select {
  border: none !important;
}
textarea {
  border: none !important;
}

textarea:focus,
input:focus,
select,
.page-link:focus {
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
}

/* comment */

.comment-div p,
.comment-div small {
  font-size: 12px;
}

/* test */

.link-sm {
  font-size: 12px;
  color: #ff3751;
}

.bg-1 {
  background-color: #ff3751 !important;
}
.bg-2 {
  background-color: #61e593 !important;
}

/* Button  */
.btn {
  font-size: 12px !important;
}
.btn-primary :focus {
  background-color: #ff3751 !important;
}
.btn.btn-primary {
  background-color: #ff3751 !important;
  border: 2px solid #ff3751;
}
.btn.btn-primary:focus {
  background-color: #ff3751;
  border: 2px solid #ff3751;
}

.bg-success {
  background-color: #00a67e !important;
  /* color: #000; */
}
/* Custome w */
.wt-50 {
  width: 50px;
}
.wt-60 {
  width: 60px;
}
.wt-70 {
  width: 70px;
}
.wt-80 {
  width: 80px;
}
.wt-90 {
  width: 90px;
}
.wt-100 {
  width: 100px;
}
.wt-150 {
  width: 120px;
}

.wt-150 {
  width: 150px;
}

.mxw-70 {
  max-width: 70px;
}

.border-wr,
.border-wr:hover {
  border-right: 2px solid white !important;
}

/* Post  */
.ol-posts li {
  list-style-type: none !important;
}
.ol-posts li::marker {
  color: gray !important;
}

.learn-more {
  color: #f5405f;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}
.learn-more:hover {
  /* color: white; */
  color: #f5405f;
  text-decoration: none;
}

/* Tables */

.cutome-tables tr td {
  border: none;
}
.table {
  margin-bottom: 30px;
}

.table thead {
  font-size: 12px;
}
.nowrap {
  white-space: nowrap;
}

.table > :not(caption) > * > * {
  vertical-align: middle;
}

.time-col {
  font-size: 10px;
}

.b-amount {
  min-width: 50px !important;
}

/* .d-table  tr{
border-bottom: 1px solid #ff3751;
} */

.d-table > :not(caption) > * > * {
  border-color: #ff3751;
}
/* Media Query */
/* @media screen and (min-width: 330px) and (max-width: 400px) {

  .label {
    font-size: 12px !important;
  }
  .btn {
    font-size: 10px !important;
  }
  .plan-wrap a.his-btn,
  .plan-wrap .btn {
    font-size: 10px;
  }
} */

/* Custome checkbox start */

.custom-checkbox {
  max-width: 400px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.custom-checkbox .checkbox-container {
  height: 60px;
  width: 90px;
  /* background-color: #ffffff; */
  box-shadow: 0 0 25px rgba(17, 1, 68, 0.08);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  padding: 2px;
  border: 0.02rem solid #ff3751;
  color: white;
}
.custom-checkbox .checkbox-container:hover {
  background-color: #ff3751;
}

.custom-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 12px;
  color: #fff;
  right: 5px;
  top: -15px;
}

.custom-checkbox input[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  color: #fff;
}

.custom-checkbox .checkbox-container img {
  width: 30px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 5px;
  /* bottom: 0; */
  cursor: pointer;
}

.custom-checkbox .checkbox-container small {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
  text-align: center;
  font-size: 12px;
  color: white;
}

/* Custome checkbox end */

/* cursor pointer */

.cursor-pointer {
  cursor: pointer;
}

/* on off rest button */
.on-off-btn {
  display: flex;
  justify-content: space-between;
}
.on-off-btn2 {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
}
.btn3 {
  background: #6d6e71 !important;
  border: 2px solid #6d6e71 !important;
  color: #fff;
}
/* close menu */
.fixed-menu .close-menu {
  position: fixed;
}

.pad-right-5 {
  padding-right: 5px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  background-color: #151726;
}

/* padding left 0 */
.pad-left-0 {
  padding-left: 0px;
}
/* pink color hover */
.color-pink:hover {
  color: #ff3751;
}
.bg-color {
  background-color: #151726;
}
.color-pink-2:hover {
  background-color: #ff3751;
  color: white;
}
.text-pink-3 {
  /* background-color: #ff3751; */
  color: #ff3751;
}

/* white color hover */
.color-white:hover {
  background-color: white;
}
/* system message */
.sys-msg {
  padding-left: 10px;

  text-align: justify;
}
.sys-msg-2 {
  padding-right: 10px;
  font-size: 12px;
}
/* margin top 0 */
.margin-top-0 {
  margin-top: 0;
}
/* margin right 5px */
.margin-right-5 {
  margin-right: 5px;
}
/* padding top 20px */
.pad-top-20 {
  padding-top: 20px;
}
/* message datebox */
.msg-datetime-box2 {
  position: relative;
  min-width: 50px;
  flex-direction: column-reverse;
  margin-bottom: 10px;
}

/* margin top 5px */
.margin-top-5 {
  margin-top: 5px;
}

.color-pink-3 {
  color: #ff3751;
}
.marg-top-8 {
  margin-top: 8px;
}
.chk-box {
  accent-color: #ff3751;
}
.chx-box1 .form-check-input:checked {
  background-color: #ff3751 !important;
  border-color: #ff3751 !important;
}
.pad-right-5 {
  padding-right: 5px;
}
.cur-pointer {
  cursor: pointer;
}
.color-pink:hover {
  color: #ff3751;
}
.pad-right-5 {
  padding-right: 5px;
}
.cur-pointer {
  cursor: pointer;
}
.color-pink:hover {
  color: #ff3751;
}

.color-white2:hover {
  color: #fff;
  background-color: #ff3751;
}
.padding-top-4 {
  padding-top: 4px;
}
.delete-selected-photos {
  border-radius: 50%;
  width: 30px;
  padding-bottom: 3px;
  position: absolute;
  margin-left: 174px;
  margin-top: -6px;
  background-color: #fff;
  color: gray;
  border: none;
}
.delete-selected-photos:hover {
  color: #fff;
  background-color: #ff3751;
}
.delete-selected-video-position {
  max-height: 300px;
}

.delete-selected-video {
  border-radius: 50%;
  width: 30px;
  padding-bottom: 3px;
  position: absolute;
  margin-left: 385px;
  margin-top: -15px;
  background-color: #fff;
  color: gray;
  border: none;
}

.post-videos {
  width: 100%;
  margin-bottom: 20px;
  height: 250px;
}
.delete-selected-video:hover {
  color: #fff;
  background-color: #ff3751;
}
.upload-img {
  text-align: center;
  width: 100%;
  color: #96a0a8;
  font-size: 60px;
}
.upload-img-text {
  text-align: center;
  width: 100%;
}
.text-red-add-details {
  color: yellow;
  font-size: 10px;
  padding-left: 15px;
  padding-top: 2px;
}
.pad-bot-10 {
  padding-bottom: 10px;
}
.text-red-add-details-2 {
  color: yellow;
  font-size: 10px;
  padding-left: 10px;
  padding-top: 2px;
}
.text-red-add-details-3 {
  color: yellow;
  font-size: 10px;
  padding-left: 12px;
  padding-top: 2px;
}
.next-button {
  color: #fff;
  background-color: #ff3751;
  border: 2px solid #ff3751;
}
.next-button:hover {
  color: #fff;
  background-color: #151726;
  border: 2px solid #ff3751;
}
a.his-btn {
  display: block;
  color: #f5405f;
  text-decoration: underline;
  font-size: 12px;
}
.marg-client-main-page {
  margin-top: -560px;
}
.cus-cont-label {
  margin-bottom: 0;
  border-radius: 6px;
  height: 190px;
  object-fit: cover;
}
.delete-photo:disabled {
  border-color: #6d6e71;
  background-color: #6d6e71;
}
.delete-photo {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
}
.post-box-2 {
  background: #f8405f;
  border-radius: 6px;
  padding: 1px 5px;
  position: relative;
}
.post-box-2 textarea {
  font-weight: 300;
  font-size: 10px;
  resize: none;
  background: transparent;
  border: none;
  color: #fff;
  border-radius: 0;
  min-height: 50px !important;
}
.post-box-2 input::placeholder,
.post-box-2 textarea::placeholder {
  color: #fff;
}
.post-box-2 input:focus,
.post-box-2 textarea:focus {
  background-color: transparent;
  -webkit-box-shadow: none;
  border-color: #fff;
  color: #fff;
}

.primary-button-2 {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  color: #fff;
}
.primary-button-3 {
  background-color: #151726;
  border: 2px solid #151726 !important;
  color: #fff;
}
/* .primary-button-2:hover {
  background-color: #ff3751 !important;
  color: #fff;
  border: 2px solid #ff3751 !important;
} */
input.street-no::-webkit-outer-spin-button,
input.street-no::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.street-no[type="number"] {
  -moz-appearance: textfield;
}
.main-photo {
  margin-left: 8px;
}

#map {
  height: 300px;
  width: 100%;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}
.line-height-body-dimension {
  line-height: 0 !important;
}
.edit-profile {
  background-color: #6d6e71 !important;
  border: 1px solid #6d6e71 !important;
  pointer-events: none !important;
}
.edit-profile:hover {
  cursor: pointer !important;
}
.modal-important {
  font-size: 11px;
  color: #f5405f;
}
.modal-reminder {
  font-size: 12px;
  color: white;
}
.close-blog {
  border-radius: 50%;
  width: 28px;
  padding-bottom: 3px;
  position: absolute;
  margin-left: 312px;
  margin-top: -90px;
  border: 2px solid white;
  height: 28px;
}
.close-blog:hover {
  color: #fff;
  background-color: #ff3751;
  border: 2px solid #ff3751;
}
.spacer {
  margin-top: 6rem;
  margin-left: 2.8rem;
}

/* barcode container */
.anim-box {
  position: relative;
  width: 300px;
  height: 300px;
  padding: 25px 30px;
  transition: transform 0.6s ease-out;
}

/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: white;
  border-style: solid;
  content: " ";
}

/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}

/* bottom right corner */
.anim-box > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

/* bottom left corner */
.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}

/* barcode bars */
.anim-item {
  display: inline-block;
  background-color: white;
  height: 100px;
}

.anim-item-sm {
  width: 2px;
  margin-right: 3px;
}

.anim-item-md {
  width: 3px;
  margin-right: 2px;
}

.anim-item-lg {
  width: 5px;
  margin-right: 5px;
}

/* grow on hover */
/* .anim-box:hover {
	transform: scale(1.5, 2);
}
 */
.anim-box .scanner {
  animation-play-state: running;
}

/* animated laser beam */
.scanner {
  width: 100%;
  height: 3px;
  background-color: green;
  opacity: 0.7;
  position: relative;
  box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
  top: 50%;
  animation-name: scan;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}
.no-data-found {
  border: 1px solid #ff3751;
  border-radius: 8px; 
  padding: 20px;
  display: inline-block; 
  width: 100%;
  text-align: center;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 8px 10px rgba(170, 11, 23, 0.49);
    top: 50%;
  }
  25% {
    box-shadow: 0px 6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 5px;
  }
  75% {
    box-shadow: 0px -6px 8px 10px rgba(170, 11, 23, 0.49);
    top: 98%;
  }
}
.preview-container {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.preview-container-1 {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.ads_bg1 {
  background-image: url("../assets/ads_bg/bg1.png");
}
.ads_bg2 {
  background-image: url("../assets/ads_bg/bg2.png");
}
.ads_bg3 {
  background-image: url("../assets/ads_bg/bg3.png");
}
.ads_bg4 {
  background-image: url("../assets/ads_bg/bg4.png");
}

.bk-grobs {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.1);
}

.preview-card-img {
  height: 400px;
  width: 100%;
  max-width: 345px;
  /* border-radius: 15px; */
}

.preview-card-container {
  height: 400px;
  width: 350px;
  max-width: 350px;
  /* background-image: url("../assets/imgs/card-fram-1.png"); */
  background-image: url("../assets/imgs/flower\ banner\ dsk.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: absolute;
}

.br-15 {
  border-radius: 15px;
}
.br-10 {
  border-radius: 10px;
}
.br-5 {
  border-radius: 5px;
}

.bg-flower {
  width: 100% !important;
  position: relative;
  bottom: 0;
}

.bottom-flower {
  height: 100px;
  width: 100%;
  background-size: 100% 100%;
  margin-top: -90px;
  position: relative;
}

.clt-img2 {
  width: 220px;
  height: 230px;
  margin-top: -200px;
  content: url("../../public/img/ad-details-145019-floral-photos-circle-border-frame.png");
}
.clt-img2-1 {
  width: 220px;
  height: 230px;
  margin-top: -200px;
  /* content: url("../../public/img/ad-details-145019-floral-photos-circle-border-frame.png"); */
}
.square {
  height: 45px;
  width: 50px;
  background-color: #151726;
  border: 1px solid yellow;
  border-top-right-radius: 20%;
  border-top-left-radius: 20%;
  margin-left: 30px;
  margin-top: 10px;
}
.square2 {
  height: 28px;
  width: 50px;
  background-color: #ff3751;
  border: 1px solid yellow;
  color: white;
  margin-left: 30px;
}
.square3 {
  height: 30px;
  width: 100%;
  background-color: #e801ff;
  border-top: 1px solid #fff200;
  border-bottom: 1px solid #fff200;
}
.centered {
  color: white;
  font-size: 20px;
  margin-top: -35px;
}

.service-b {
  height: 100%;
  width: 100%;
  /* padding: 30px 35px 30px 35px; */
  /* background-image: url("../assets/imgs/Rectangle\ border.png"); */
  /* background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat; */
  background: #1d1d1d;
  color: #e801ff;
  border-radius: 10px;
  word-wrap: break-word;
}
.service-div {
  border: 2px solid #f5405e;
  height: auto;
  border-radius: 6px;
  padding: 5px;
}
.service-content {
  border: 1px solid #f5405e;
  height: auto;
  border-radius: 6px;
}
.table-preview {
  border: 1px solid #409bf5;
}
.light-button2 {
  background-color: #6d6e71 !important;
  padding: 3px 0px;
  color: white;
  font-size: 11px;
  border: 2px solid #6d6e71;
}
.light-button2:disabled {
  background-color: #6d6e71 !important;
  padding: 3px 0px;
  color: white;
  font-size: 11px;
  border: 2px solid #6d6e71;
}
.d-input2 {
  background-color: #ff3751;
  border: 2px solid #ff3751;
  border-radius: 20px;
  height: 30px;
}
.d-input2:disabled {
  opacity: 1;
}
/* .close-popover {
  border-radius: 50%;
  width: 30px;
  padding-bottom: 3px;
  position: absolute;
  margin-left: 250px;
  margin-top: -29px;
} */

.login-language {
  background-color: transparent;
  color: white;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 14px;
  padding: 3px 3px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.login-language:hover {
  background-color: #ff3751;
  color: white;
}
.menu-language {
  background-color: transparent;
  color: white;
  padding: 2px;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 14px;
  padding: 3px 3px;
  position: absolute;
  right: 110px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.menu-language:hover {
  background-color: #ff3751;
  color: white;
}

.margin-left-10 {
  margin-left: 10px;
}

.color-white3 {
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff3751;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

#streetno::placeholder {
  color: rgb(196, 195, 195);
}

.readonly {
  background-color: #6d6e71;
  pointer-events: none;
  border: none !important;
}

.no-button {
  color: white;
  background-color: #6d6e71;
  border: 2px solid #6d6e71;
}
.no-button:hover {
  color: white;
  background-color: #6d6e71;
  border: 2px solid #6d6e71;
}

.inst-r-four-img {
  /* width: 0px;
  height: 40px; */
  max-width: 50px;
  max-height: 50px;
}

@media only screen and (max-width: 400px) {
  /*iPhone 6/7/8/X */

  .label {
    font-size: 10px;
  }
  .rest-div {
    padding-right: 0px;
  }
  .time-r-div {
    padding-left: 0px;
  }

  /* button */
  .btn {
    font-size: 10px !important;
  }

  .plan-wrap a.his-btn,
  .plan-wrap .btn {
    font-size: 10px;
  }
  /* profile image */

  .flower-box2 .flower-top-balacnce {
    font-size: 25px !important;
  }
  .clt-img {
    height: 50px !important;
    width: 50px !important;
  }

  .plan-wrap a.his-btn,
  .plan-wrap .btn {
    font-size: 10px;
  }

  /*  */

  .account-box .card-body {
    padding: 15px 5px 10px 5px !important;
  }
  .account-box .card-title {
    font-size: 10px;
  }
  .account-box img {
    width: 30px !important;
  }
  .account-box .fa-heart {
    font-size: 30px;
  }
  .account .exc-btn {
    font-size: 10px;
  }
  .account-box h4 {
    font-size: 15px !important;
  }
  .account-box .abs-btn {
    font-size: 10px !important;
    padding: 5px 0 !important;
    width: 70px;
    margin: 0 5px;
    bottom: -25px;
    width: 90%;
  }
  .account-box .exc-btn {
    margin-right: 0px !important;
  }
  .delete-selected-photos {
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 150px !important;
    margin-top: -6px !important;
  }
  .delete-selected-video {
    border-radius: 50% !important;
    width: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 334px !important;
    margin-top: -15px !important;
  }
  .text-red-add-details {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-2 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-3 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 12px !important;
    padding-top: 2px !important;
  }
  .special-cat-width {
    width: 127px;
  }
  .marg-client-main-page {
    margin-top: -315px !important;
  }
  .spacer {
    margin-top: 6rem !important;
    margin-left: 1.7rem !important;
  }
  /*  */
}

@media only screen and (max-width: 380px) and (max-height: 812px) {
  /*iPhone X*/
  .marg-client-main-page {
    margin-top: -455px !important;
  }
  .close-blog {
    border-radius: 50% !important;
    width: 28px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 293px !important;
    margin-top: -90px !important;
    border: 2px solid white !important;
    height: 28px !important;
  }
  .close-blog:hover {
    color: #fff !important;
    background-color: #ff3751 !important;
    border: 2px solid #ff3751 !important;
  }
  .spacer {
    margin-top: 6rem !important;
    margin-left: 1.7rem !important;
  }
}
@media only screen and (min-width: 390px) and (max-height: 850px) {
  /*iPhone 12 Pro*/
  .marg-client-main-page {
    margin-top: -500px !important;
  }
  .spacer {
    margin-top: 6rem !important;
    margin-left: 2rem !important;
  }
}
/* Devive with width less then 330px */
@media (max-width: 400px) {
  .date-models .modal-content,
  .date-models .modal-content {
    padding: 40px 10px !important;
  }

  .date-models .modal-body .date-contents {
    /* gap: 10px !important; */
    /* display: flex !important; */
    /* width: 200px !important; */
  }
  .date-day {
    margin: 5px !important;
    font-size: 15px;
  }
}

@media (min-width: 360px) and (max-width: 450px) {
  .preview-card-container {
    height: 250px !important;
    width: 150px !important;
    max-width: 150px;
  }

  .preview-card-img {
    height: 250px !important;
    width: 150px !important;
    max-width: 150px;
  }
}
@media (min-width: 321px) and (max-width: 360px) {
  .preview-card-container {
    height: 250px !important;
    width: 140px !important;
    max-width: 140px;
  }

  .preview-card-img {
    height: 250px !important;
    width: 140px !important;
    max-width: 140px;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .bg_p1 {
    padding-top: 5px;
    padding-bottom: 8px;
  }
  .bg_p1 .form-check {
    height: 20px !important;
  }

  .bg_p1 .form-check-input {
    height: 15px;
    width: 15px;
    margin-top: 7px !important;
  }

  .ext-input {
    width: 90px;
  }
  .tab-group li a {
    font-size: 12px !important;
  }
}
@media (max-width: 320px) {
  .text-14,
  .text-12,
  .rgd-mob span,
  .rgd-mob a ,.dec1{
    font-size: 10px !important;
  }
  .tab-group li a {
    font-size: 8px !important;
  }
  .welcome-btn {
    padding-right: 40px;
    padding-left: 20px;
    font-size: 10px !important;
  }
  .welcome-btn-2 {
    font-size: 10px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .custom_photo1 {
    height: 180px;
    max-height: 180px;
  }
  .custom_photo {
    height: 130px;
    max-height: 130px;
  }

  .b-advances {
    display: none;
  }
  .b-advances-1 {
    display: block !important;
  }
}
@media (min-width: 320px) and (max-width: 390px) {
  .account-box .card-title {
    font-size: 16px;
  }
  .ext-inputs {
    width: 100px;
  }

  .customModels .modal-dialog {
    margin-top: 180px;
  }
}

/* Devive with width less then 330px */
@media only screen and (max-width: 330px) {
  /*iPhone 5/SE*/
  .label {
    font-size: 10px;
  }
  .ext-inputs {
    width: 120px;
  }

  /* button */
  .btn {
    font-size: 10px !important;
  }

  .on-off-btn .btn {
    padding: 2px 8px;
  }
  .on-off-btn2 {
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 12px !important;
  }
  .on-off-btn3 {
    padding-left: 12px !important;
  }
  .switch {
    width: 50px;
    height: 22px;
    margin-left: 10px;
    font-size: 8px;
  }
  .slider.round {
    border-radius: 34px;
    line-height: 25px;
    font-size: 8px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 1px;
  }
  .plan-wrap a.his-btn,
  .plan-wrap .btn {
    font-size: 10px;
  }

  /*  */

  .account-box .card-body {
    padding: 15px 5px 10px 5px !important;
  }
  .account-box .card-title {
    font-size: 10px;
  }
  .account-box img {
    width: 30px !important;
  }
  .account-box .fa-heart {
    font-size: 30px;
  }
  .account .exc-btn {
    font-size: 10px;
  }
  .account-box h4 {
    font-size: 15px !important;
  }
  .account-box .abs-btn3 {
    font-size: 10px !important;
    padding: 5px 0 !important;
    width: 70px;
    margin: 0 5px;
    bottom: -25px;
    width: 90%;
  }
  .account-box .exc-btn {
    margin-right: 0px !important;
  }
  .request-btn {
    background: #ff3751;
    border: 1px solid #ff3751;
    color: #fff;
    border-radius: 15px;
    line-height: 26px;
    font-size: 12px;
    width: 100%;
  }
  .request-btn.f-btn {
    background: none;
  }
  .table-wrap {
    background: #151726;
    padding: 20px 0;
    border-radius: 30px;
    margin-left: -6px;
  }
  .rounded-button.coin-btn {
    bottom: -15px;
    left: 3px;
  }
  .delete-selected-photos {
    border-radius: 50% !important;
    width: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 120px !important;
    margin-top: -6px !important;
  }
  .delete-selected-video {
    border-radius: 50% !important;
    width: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 279px !important;
    margin-top: -15px !important;
  }
  .add-details-img-height {
    height: 130px !important;
  }
  .text-red-add-details {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-2 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-3 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 12px !important;
    padding-top: 2px !important;
  }
  .marg-client-main-page {
    margin-top: -230px !important;
  }
  .cus-cont-label {
    margin-bottom: 0;
    border-radius: 6px;
    height: 130px;
    object-fit: cover;
  }
  /* .obj-cov {
    object-fit:calc();
 
  } */
  .close-blog {
    border-radius: 50% !important;
    width: 28px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 220px !important;
    margin-top: -90px !important;
    border: 2px solid white !important;
    height: 28px !important;
  }
  .close-blog:hover {
    color: #fff !important;
    background-color: #ff3751 !important;
    border: 2px solid #ff3751 !important;
  }
  .spacer {
    margin-top: 6rem !important;
    margin-left: 0 !important;
  }
  /*  */
  .date-models .modal-content,
  .date-models .modal-content {
    padding: 40px 10px !important;
  }

  .date-models .modal-body .date-contents {
    /* gap: 10px !important; */
    /* display: flex !important; */
    /* width: 200px !important; */
  }
  .date-day {
    margin: 5px !important;
    font-size: 13px;
  }
}
@media only screen and (min-width: 330px) and (max-width: 400px) {
  .on-off-btn .btn {
    padding: 10px 15px;
  }
}
@media only screen and (min-width: 400px) and (max-width: 578px) {
  /*iPhone 6/7/8 Plus*/
  .on-off-btn .btn {
    padding: 8px 15px;
  }
  .customModels .modal-dialog {
    margin-top: 200px;
  }
  .ext-inputs {
    width: 100px;
  }
  .delete-selected-photos {
    border-radius: 50% !important;
    width: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 167px !important;
    margin-top: -6px !important;
  }
  .delete-selected-video {
    border-radius: 50% !important;
    width: 30px !important;
    padding-bottom: 3px !important;
    position: absolute !important;
    margin-left: 372px !important;
    margin-top: -15px !important;
  }
  .text-red-add-details {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 15px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-2 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 10px !important;
    padding-top: 2px !important;
  }
  .text-red-add-details-3 {
    color: yellow !important;
    font-size: 10px !important;
    padding-left: 12px !important;
    padding-top: 2px !important;
  }
  .marg-client-main-page {
    margin-top: -405px !important;
  }

  /* table */

  .text-scheduled {
    font-size: 10px;
  }
  .spacer {
    margin-top: 6rem !important;
    margin-left: 2.7 !important;
  }
}
/* Desktop Version CSS */
@media (min-width: 1400px) {
  .container {
    max-width: 1000px;
  }
  .label {
    font-size: 14px;
  }

  .form-control,
  .form-select,
  .d-input {
    height: 35px;
  }
  .form-check {
    height: 35px;
    padding-top: 3px;
  }

  /* start instant Review Post */

  .looking_emoji {
    width: 40px;
    height: 40px;
  }
  .mir-right {
    padding: 20px;
  }
  .mir-right ul li {
    padding: 0 10px 0 0;
  }
  .mir-right ul li span {
    line-height: 30px;
    font-size: 14px;
  }
  .mir-right h2 {
    font-size: 22px;
  }
  .mir-right h2 span {
    font-size: 14px;
  }
  .mir-right p {
    font-size: 16px;
    line-height: 25px;
  }
  .mir-right .post-edit {
    font-size: 16px;
  }

  /* Left Side  */
  .mir-left .ranking .fa-crown {
    font-size: 50px;
  }
  .mir-left .ranking a {
    font-size: 18px;
    margin-top: -5px;
  }
  .mir-left ul li {
    padding: 0 15px;
    position: relative;
  }
  .mir-left ul li:after {
    height: 70px;
  }
  .mir-left ul li strong {
    font-size: 14px;
  }
  .mir-left h6 {
    font-size: 20px;
  }
  .mir-left .clt-img {
    width: 100px;
    height: 100px;
  }
  /* end instant Review Post */

  /* table */
  .table thead {
    font-size: 16px;
  }

  /*  */

  .profile-title,
  .profile-n {
    font-size: 16px;
  }
  .fa-ellipsis-vertical {
    font-size: 18px;
  }
  .fa-star {
    font-size: 18px;
  }

  /*  chat list and Comment on instant review  */

  .chat-DIV p {
    font-size: 16px;
    line-height: 25px;
  }
  .chat-DIV .clt-img1 {
    width: 60px;
    height: 60px;
  }
  .chat-DIV .chat-count {
    min-width: 30px;
    height: 30px;
    font-size: 11px !important;
    color: #000;
  }

  /* Flower */
  .flower-bottom h5 {
    width: auto;
  }
  .flower-top h3 {
    width: auto;
  }

  /* Insta post  */
  /* .d-table h6  {
    font-size: 18px !important;
  } */
}
/* Desktop Version CSS */
@media (min-width: 1200px) {
  /* Flower */
  .flower-bottom h5 {
    width: auto;
  }
  .flower-top h3 {
    width: auto;
  }

  /* Insta post  */
  .d-table h6 {
    font-size: 12px;
  }
  .postlist-main-title {
    font-size: 16px;
  }
  .post-likes,
  .post-saved,
  .post-edit,
  .postlist-title,
  .schedule-list {
    font-size: 16px;
  }

  .postlist-datetime {
    width: 150px;
  }
}

/* Main */
.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); / */
  background-color: #151726;
  /* background-color: #2d2e38; */
  z-index: 999;
}
.loader-backdrop-12 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .1); 
  /* background-color: #151726; */
  /* background-color: #2d2e38; */
  z-index: 999;
}
.loader-backdrop-13 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7); 
  /* background-color: #151726; */
  /* background-color: #2d2e38; */
  z-index: 999;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  padding: 20px;
  border-radius: 8px;
}

.company-logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #ff3751;
  /* border-top: 8px solid #3498db;  */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*  */
.loader-backdrop-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  /* background-color: #151726; */
  /* background-color: #2d2e38; */
  z-index: 999;
}

.loader-container-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* background: #151726; */
  padding: 40px !important;
  border-radius: 8px;
  width: 100%;
}

.loader-2 {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ff3751,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ff3751, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ff3751,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ff3751,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ff3751,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ff3751,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ff3751,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ff3751;
  }
}

.loader-3 {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader-3:before,
.loader-3:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader-3:after {
  color: #ff3751;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

.loader-4 {
  color: #ffffff;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  transform: translateZ(0);
  animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes mltShdSpin {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-5,
.loader-5:before,
.loader-5:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader-5 {
  color: #f2405f;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader-5:before,
.loader-5:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader-5:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader-5:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loader-12 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-12::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #ff3d00;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-13 {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-13::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #ff3d00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-14 {
  width: 48px;
  height: 48px;
  position: relative;
}
.loader-14::before,
.loader-14::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 48em;
  height: 48em;
  background-image: radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0),
    radial-gradient(circle 10px, #ff3751 100%, transparent 0);
  background-position: 0em -18em, 0em 18em, 18em 0em, -18em 0em, 13em -13em,
    -13em -13em, 13em 13em, -13em 13em;
  background-repeat: no-repeat;
  font-size: 0.5px;
  border-radius: 50%;
  animation: blast 1s ease-in infinite;
}
.loader-14::after {
  font-size: 1px;
  background: #fff;
  animation: bounce 1s ease-in infinite;
}

@keyframes bounce {
  0%,
  100% {
    font-size: 0.75px;
  }
  50% {
    font-size: 1.5px;
  }
}
@keyframes blast {
  0%,
  40% {
    font-size: 0.5px;
  }
  70% {
    opacity: 1;
    font-size: 4px;
  }
  100% {
    font-size: 6px;
    opacity: 0;
  }
}




.invitation-profile-img {
  width: 80px;
  height: 80px;

  @media ((min-width: 320px) and (max-width: 359px)) {
    width: 70px;
    height: 70px;
  }
}