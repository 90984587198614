/*
 * Bootstrap Image Checkbox v0.1.0 (https://iqbalfn.github.io/bootstrap-image-checkbox/)
 * Copyright 2019 Iqbal Fauzi
 * Licensed under MIT (https://github.com/iqbalfnn/bootstrap-image-checkbox/blob/master/LICENSE)
 */
.custom-control.image-checkbox {
  position: relative;
  padding-left: 0;
}

.custom-control.image-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:after,
.custom-control.image-checkbox
  .custom-control-input:checked
  ~ .custom-control-label:before {
  opacity: 1;
}

.custom-control.image-checkbox label {
  cursor: pointer;
}

.custom-control.image-checkbox label:before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control.image-checkbox label:after,
.custom-control.image-checkbox label:before {
  transition: opacity 0.3s ease;
  opacity: 0;
  right: 0.25rem;
}

.custom-control.image-checkbox label:focus,
.custom-control.image-checkbox label:hover {
  opacity: 0.8;
}

.custom-control.image-checkbox label img {
  border-radius: 6px;
  height: 190px;
  object-fit: cover;
}

.form-group-image-checkbox.is-invalid label {
  color: #dc3545;
}

.form-group-image-checkbox.is-invalid .invalid-feedback {
  display: block;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.example{ border: 4px solid #eee; margin-bottom:30px;}
.example-preview{ padding: 1.5rem; }
.example > pre{ border-top: 4px solid #eee;margin:0; }
.no-html{ display: none; }
.highlihgt-grid .row + .row{ margin-top: 1rem;}
.highlihgt-grid [class^="col-"]{
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control.image-checkbox label:after, .custom-control.image-checkbox label:before {
  transition: opacity .3s ease;
  opacity: 1;
  right: 0.25rem;
  background-color: #fff;
  border: 1px solid #f8405f;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  right: -1.5rem;
  display: block;
  width: 15px;
  height: 15px;
  content: "";
  background: no-repeat 50%/50% 50%;
  background-size: contain;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  right: -1.5rem;
  display: block;
  width: 15px;
  height: 15px;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control.image-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  opacity: 1;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #fff;
  background-color: #fff;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("../../public/img/check.png");
}
